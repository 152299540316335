import { createRef, useCallback, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { BaseButton, KEYS, SharedConfigManager } from "blace-frontend-library";
import { BaseConfirmationModal } from "@/src/component/base/BaseConfirmationModal";
import styles from "./FileUpload.module.scss";

export enum FileUploadErrors {
  UploadFailed = "File upload failed, please try again",
  MaxSizeLimited = "File must be a PDF under 20MB",
  NonPDFFormat = "File upload failed, please select a PDF file",
}

export enum UploaderInfo {
  Invoice = "Upload the final invoice",
  FloorPlan = "Upload the Floor plan",
  TearSheet = "Upload the Tear sheet",
}

interface InvoiceUploadProps {
  fileType?: UploaderInfo;
  fileName: string;
  fileSize: number;
  fileUploadError: string;
  isMaxSizeFileLimited: boolean;
  isFileLoading: boolean;
  setUploadFileData: (data: FileList) => void;
  openInNewTabHandler: () => void;
  removeFileHandler: () => void;
  canOpenFileUploader?: () => boolean;
}

const FileUpload = ({
  fileType,
  fileName,
  fileSize,
  fileUploadError,
  isMaxSizeFileLimited,
  isFileLoading,
  setUploadFileData,
  openInNewTabHandler,
  removeFileHandler,
  canOpenFileUploader = () => true,
}: InvoiceUploadProps) => {
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const fileInputRef = createRef<HTMLInputElement>();
  const SVG_URL = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);
  const isFileUploaded = fileName && fileSize;
  const initialFileUploadState = !isFileUploaded && !isFileLoading;
  const fileUploadedState = isFileUploaded && !isFileLoading;

  const errorUpload = fileUploadError || isMaxSizeFileLimited;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setUploadFileData(event.target.files);
    }
  };

  const onNewTabOpen = () => {
    openInNewTabHandler();
  };

  const onRemoveFile = useCallback(() => {
    removeFileHandler();

    if (fileInputRef.current && (fileInputRef.current.value || fileInputRef.current.files)) {
      fileInputRef.current.value = "";
      fileInputRef.current.files = null;
    }

    setIsDeleteConfirmModalOpen(false);
  }, [removeFileHandler, fileInputRef, setIsDeleteConfirmModalOpen]);

  const openDeleteConfirmationModal = () => {
    setIsDeleteConfirmModalOpen(true);
  };

  const closeDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmModalOpen(false);
  }, [setIsDeleteConfirmModalOpen]);

  const retryUploadHandler = () => {
    if (fileInputRef.current && fileInputRef.current.files) {
      setUploadFileData(fileInputRef.current.files);
    }
  };

  const handleOpenFilesUploader = (event: React.MouseEvent<HTMLLabelElement>) => {
    event.preventDefault();
    if (!canOpenFileUploader || canOpenFileUploader()) {
      fileInputRef?.current?.click();
    }
  };

  return (
    <>
      {initialFileUploadState && (
        <div className={styles.uploadFile}>
          <Typography className={styles.uploadMessage}>
            {fileType} <br />
            (File must be a PDF under 20MB)
          </Typography>
          <label htmlFor={`file-upload-${fileType}`} onClick={handleOpenFilesUploader}>
            <div className={styles.uploadButton}>
              <img src={`${SVG_URL}/fileUploadIcon.svg`} alt="upload file" />
              Upload file
            </div>
          </label>
        </div>
      )}
      {isFileLoading && (
        <Box className={styles.loadingSpinnerWrapper}>
          <CircularProgress size={30} color="secondary" />
        </Box>
      )}
      <input
        onChange={handleChange}
        id={`file-upload-${fileType}`}
        data-testid={`file-upload-${fileType}`}
        type="file"
        accept=".pdf"
        ref={fileInputRef}
        className={styles.hiddenFileInput}
      />
      {fileUploadedState && (
        <>
          <div className={errorUpload ? styles.uploadFileInfoFail : styles.uploadFileInfo}>
            {errorUpload !== FileUploadErrors.NonPDFFormat && (
              <img src={`${SVG_URL}/pdfIcon.svg`} alt="pdf file" />
            )}
            <div className={styles.fileData}>
              <Typography className={errorUpload ? styles.fileNameFail : styles.fileNameSuccess}>
                {fileName}
              </Typography>
              {!errorUpload && <Typography className={styles.fileSize}>{fileSize}</Typography>}
            </div>
            {errorUpload ? (
              <div onClick={retryUploadHandler}>
                <div className={styles.retryUpload}>
                  <img src={`${SVG_URL}/retryButtonIcon.svg`} alt="RetryIcon pdf file" />
                </div>
              </div>
            ) : (
              <BaseButton className={styles.checkButton} onClick={onNewTabOpen}>
                <img src={`${SVG_URL}/checkFileButtonIcon.svg`} alt="check pdf file" />
              </BaseButton>
            )}
            <BaseButton
              className={styles.removeButton}
              onClick={openDeleteConfirmationModal}
              data-testid="remove-button"
            >
              <img src={`${SVG_URL}/removeFileButtonIcon.svg`} alt="remove pdf file" />
            </BaseButton>
          </div>
          <BaseConfirmationModal
            isOpen={isDeleteConfirmModalOpen}
            handleClose={closeDeleteConfirmationModal}
            handleConfirm={onRemoveFile}
            confirmationText="Are you sure you want to delete this file?"
          />
          {errorUpload && (
            <Typography className={styles.failure}>
              {fileUploadError ? fileUploadError : FileUploadErrors.UploadFailed}
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default FileUpload;
