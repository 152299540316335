import React, { memo } from "react";
// import { Typography } from "@mui/material";
import { Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { BaseButton, DragBar, useBreakPointUp, useDragToClose } from "blace-frontend-library";
import Draggable from "react-draggable";
import styles from "./BaseConfirmationModal.module.scss";
interface CustomPaperProps {
  children?: React.ReactNode;
}

const CustomPaper = memo(function CPaper(props: CustomPaperProps) {
  return <>{props.children}</>;
});
interface BaseConfirmationModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  confirmationText: string;
  testId?: string;
}

function BaseConfirmationModal({
  isOpen,
  handleClose,
  handleConfirm,
  confirmationText,
  // testId,
}: BaseConfirmationModalProps) {
  // const isMobile = useBreakPointDown("md");
  const { handleDrag, handleDragStop } = useDragToClose({
    onDragStop: handleClose,
  });

  // const modalTestId = testId ?? "confirmation-modal";
  const isDesktop = useBreakPointUp("md");

  // const dialogProps = useMemo(() => {
  //   return {
  //     open: isOpen,
  //     onClose: handleClose,
  //   };
  // }, [isOpen, handleClose]);

  return (
    // <BaseModal
    //   dialogProps={dialogProps}
    //   onExit={handleClose}
    // >
    //   {/* <div className={styles.confirmModalTitleWrapper} data-testid={modalTestId}>
    //     <Typography className={styles.confirmModalTitle}>{confirmationText}</Typography>
    //   </div>
    //   <div className={styles.confirmButtons}>
    //     {isMobile && (
    //       <BaseButton
    //         className={styles.confirmButton}
    //         onClick={handleConfirm}
    //         data-testid={`${modalTestId}-mobile-confirm-button`}
    //       >
    //         Confirm
    //       </BaseButton>
    //     )}
    //     <BaseButton
    //       className={styles.cancelButton}
    //       onClick={handleClose}
    //       data-testid={`${modalTestId}-cancel-button`}
    //     >
    //       Cancel
    //     </BaseButton>
    //     {!isMobile && (
    //       <BaseButton
    //         className={styles.confirmButton}
    //         onClick={handleConfirm}
    //         data-testid={`${modalTestId}-desktop-confirm-button`}
    //       >
    //         Confirm
    //       </BaseButton>
    //     )}
    //   </div> */}
    // </BaseModal>
    <Dialog
      keepMounted
      className={styles.baseModal}
      PaperComponent={CustomPaper}
      open={isOpen}
      onClose={handleClose}
    >
      <Draggable
        position={{ x: 0, y: 0 }}
        onStop={handleDragStop}
        handle={"#room-photo-drag-bar"}
        axis="y"
        bounds={{ top: 0, bottom: 500 }}
        onDrag={handleDrag}
      >
        <DialogContent className={styles.baseModalContent}>
          {isDesktop ? (
            <div className={styles.dialogWithExitBtnContainer}>
              <div className={styles.dialogWithExitExitBtnInner}>
                <IconButton color="inherit" onClick={handleClose} data-testid="close-button">
                  <GridCloseIcon color="inherit" />
                </IconButton>
              </div>
            </div>
          ) : (
            <DragBar dragId={"room-photo-drag-bar"} />
          )}
          <div className={styles.confirmModalTitleWrapper}>
            <Typography className={styles.confirmModalTitle}>{confirmationText}</Typography>
          </div>
          <div className={styles.confirmButtons}>
            {isDesktop && (
              <BaseButton
                className={styles.confirmButton}
                onClick={handleConfirm}
                // data-testid={`${modalTestId}-mobile-confirm-button`}
              >
                Confirm
              </BaseButton>
            )}
            <BaseButton
              className={styles.cancelButton}
              onClick={handleClose}
              // data-testid={`${modalTestId}-cancel-button`}
            >
              Cancel
            </BaseButton>
            {!isDesktop && (
              <BaseButton
                className={styles.confirmButton}
                onClick={handleConfirm}
                // data-testid={`${modalTestId}-desktop-confirm-button`}
              >
                Confirm
              </BaseButton>
            )}
          </div>
        </DialogContent>
      </Draggable>
    </Dialog>
  );
}

export default BaseConfirmationModal;
