import React, { memo } from "react";
// import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { BaseButton, DragBar, useBreakPointUp, useDragToClose } from "blace-frontend-library";
import Draggable from "react-draggable";
import styles from "./BaseInfoModal.module.scss";
interface CustomPaperProps {
  children?: React.ReactNode;
}

const CustomPaper = memo(function CPaper(props: CustomPaperProps) {
  return <>{props.children}</>;
});

interface BaseInfoModalProps {
  isOpen: boolean;
  handleClose: () => void;
  infoText: string;
}

function BaseInfoModal({ isOpen, infoText, handleClose }: BaseInfoModalProps) {
  const { handleDrag, handleDragStop } = useDragToClose({
    onDragStop: handleClose,
  });

  const isDesktop = useBreakPointUp("md");
  return (
    // <BaseModal
    //   dialogProps={{
    //     open: isOpen,
    //     handleClose,
    //   }}
    //   onExit={handleClose}
    // >
    // </BaseModal>
    <Dialog
      keepMounted
      className={styles.baseModal}
      PaperComponent={CustomPaper}
      open={isOpen}
      onClose={handleClose}
    >
      <Draggable
        position={{ x: 0, y: 0 }}
        onStop={handleDragStop}
        handle={"#base-info-drag-bar"}
        axis="y"
        bounds={{ top: 0, bottom: 500 }}
        onDrag={handleDrag}
      >
        <DialogContent className={styles.baseModalContent}>
          {isDesktop ? (
            <div className={styles.dialogWithExitBtnContainer}>
              <div className={styles.dialogWithExitExitBtnInner}>
                <IconButton color="inherit" onClick={handleClose} data-testid="close-button">
                  <GridCloseIcon color="inherit" />
                </IconButton>
              </div>
            </div>
          ) : (
            <DragBar dragId={"base-info-drag-bar"} />
          )}
          <div className={styles.infoModalTitleWrapper}>
            <Typography className={styles.infoModalTitle}>{infoText}</Typography>
          </div>
          <BaseButton className={styles.infoButton} onClick={handleClose}>
            OK
          </BaseButton>
        </DialogContent>
      </Draggable>
    </Dialog>
  );
}

export default BaseInfoModal;
