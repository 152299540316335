import React, { forwardRef, memo } from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { DragBar, useBreakPointUp, useDragToClose } from "blace-frontend-library";
import Draggable from "react-draggable";
import RoomContentContext from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/RoomContentContext";
import { RoomForm } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/component/RoomForm";
import { FormRef } from "@/src/type/app";
import styles from "./RoomFormManager.module.scss";

interface CustomPaperProps {
  children?: React.ReactNode;
}

const CustomPaper = memo(function CPaper(props: CustomPaperProps) {
  return <>{props.children}</>;
});

interface RoomFormManagerProps {
  onSaveRoomFormData: () => void;
}

function RoomFormManager({ onSaveRoomFormData }: RoomFormManagerProps, ref: React.Ref<FormRef>) {
  const { selectedRoom, setSelectedRoom } = RoomContentContext.useRoomContext();

  const handleCloseFormModal = () => {
    setSelectedRoom(undefined);
  };

  const { handleDrag, handleDragStop } = useDragToClose({
    onDragStop: handleCloseFormModal,
  });

  const isDesktop = useBreakPointUp("md");

  return isDesktop ? (
    <div className={styles.selectedRoomDetails} data-testid="room-form-desktop-container">
      <RoomForm ref={ref} onSaveRoomFormData={onSaveRoomFormData} />
    </div>
  ) : (
    // <BaseModal
    //   dialogProps={{
    //     open: !!selectedRoom,
    //     onClose: handleCloseFormModal,
    //     "data-testid": "room-form-mobile-container",
    //   }}
    //   onExit={handleCloseFormModal}
    // >
    //   <Typography className={styles.modalTitle}>Rooms</Typography>
    //   <RoomForm ref={ref} onSaveRoomFormData={onSaveRoomFormData} />
    // </BaseModal>
    <Dialog
      keepMounted
      className={styles.baseModal}
      PaperComponent={CustomPaper}
      open={!!selectedRoom}
      onClose={handleCloseFormModal}
    >
      <Draggable
        position={{ x: 0, y: 0 }}
        onStop={handleDragStop}
        handle={"#room-form-drag-bar"}
        axis="y"
        bounds={{ top: 0, bottom: 500 }}
        onDrag={handleDrag}
      >
        <DialogContent className={styles.baseModalContent}>
          <DragBar dragId={"room-form-drag-bar"} />
          <Typography className={styles.modalTitle}>Rooms</Typography>
          <RoomForm ref={ref} onSaveRoomFormData={onSaveRoomFormData} />
        </DialogContent>
      </Draggable>
    </Dialog>
  );
}

export default forwardRef(RoomFormManager);
